import * as d3 from 'd3'
import {
  intFeatureVals,
  floatFeatureVals,
  intFeatureLine,
  floatFeatureLine,
  rodPatternChanges
} from '../../tools/hdf'
import React, { useEffect } from 'react'

const add = (a, b) => a + b
const mean = array => array.reduce(add) / array.length
const h = 250
const w = 700
const m = { r: 60, l: 30, t: 10, b: 30 }

const DepletionPreviewGraph = props => {
    const hdf = props.hdf
    console.log(hdf.get(hdf.keys[0]).keys)
    /* data */
    const exposure = intFeatureVals(hdf, 'CYEXP')
    const fwt = floatFeatureVals(hdf, 'TFW')
    const power = floatFeatureVals(hdf, 'RP')
    const flow = floatFeatureVals(hdf, 'WCT')
    /* lines */
    const fwtLine = floatFeatureLine(hdf, 'TFW').sort((a,b) => b[1] - a[1])
    const powerLine = floatFeatureLine(hdf, 'RP').sort((a,b) => b[1] - a[1])
    const flowLine = floatFeatureLine(hdf, 'WCT').sort((a,b) => b[1] - a[1])
    /* scales */
    const xScale = d3.scaleLinear()
      .domain([0, Math.max(...exposure)])
      .range([m.l, w - m.r - 20])
    const powerScale = d3.scaleLinear()
      .domain([Math.min(...power), Math.max(...power)])
      .range([h - m.b, m.t])
    const flowScale = d3.scaleLinear()
      .domain([Math.min(...flow), Math.max(...flow)])
      .range([h - m.b, m.t])
    const fwtScale = d3.scaleLinear()
      .domain([Math.min(...fwt), Math.max(...fwt)])
      .range([h - m.b, m.t])
    /* axes */
    const xAxis = g => g
      .attr('transform', `translate(0,${h - m.b})`)
      .call(d3.axisBottom(xScale).ticks(6))
    const fwtAxis = g => g
      .attr('transform', `translate(${m.l},0)`)
      .call(d3.axisLeft(fwtScale).ticks(6))
    /* rod patterns */
    const thesePatternChanges = rodPatternChanges(props.hdf)
    /* render */
    useEffect(() => {
      const svg = d3.select('#svg')
      svg.append('g').call(xAxis)
      svg.append('g').call(fwtAxis)
      /* fwt */
      svg.append('path')
        .datum(fwtLine)
        .attr('fill', 'none')
        .attr('stroke', 'red')
        .attr('stroke-width', '1')
        .attr('d', d3.line()
          .x(d => xScale(d[1]))
          .y(d => fwtScale(d[0])))
      svg.insert('text')
        .style('font', '9px sans-serif')
        .attr('y', 35)
        .attr('x', w - m.r + 10)
        .text('TFW')
      svg.insert('circle')
        .attr('cy', 31.5)
        .attr('cx', w - m.r + 36)
        .attr('r', 3)
        .attr('fill', 'red')
      svg.insert('circle')
        .attr('cy', h - m.b + 14)
        .attr('cx', m.l - 20)
        .attr('r', 3)
        .attr('fill', 'red')
      /* power */
      svg.append('path')
        .datum(powerLine)
        .attr('fill', 'none')
        .attr('stroke', 'green')
        .attr('stroke-width', '1')
        .attr('d', d3.line()
          .x(d => xScale(d[1]))
          .y(d => powerScale(d[0])))
      svg.insert('text')
        .style('font', '9px sans-serif')
        .attr('y', 45)
        .attr('x', w - m.r + 10)
        .text('RP')
      svg.insert('circle')
        .attr('cy', 41.5)
        .attr('cx', w - m.r + 36)
        .attr('r', 3)
        .attr('fill', 'green')
      /* flow */
      svg.append('path')
        .datum(flowLine)
        .attr('fill', 'none')
        .attr('stroke', 'goldenrod')
        .attr('stroke-width', '1')
        .attr('d', d3.line()
          .x(d => xScale(d[1]))
          .y(d => flowScale(d[0])))
      svg.insert('text')
        .style('font', '9px sans-serif')
        .attr('y', 55)
        .attr('x', w - m.r + 10)
        .text('WCT')
      svg.insert('circle')
        .attr('cy', 51.5)
        .attr('cx', w - m.r + 36)
        .attr('r', 3)
        .attr('fill', 'goldenrod')
      /* rod patterns */
      svg.append('g')
        .selectAll('rpdot')
        .data(thesePatternChanges)
        .enter()
        .append('svg')
        .attr('x', d => xScale(d[1]) - 3)
        .attr('y', d => h - m.b - 13)
        .append('polygon')
        .attr('points', '3.5,0 0,7 3.5,5.5 7,7')
        .style('fill', 'red')
    }, [])
    return <svg id='svg' height={h} width={w}></svg>
}

export default DepletionPreviewGraph